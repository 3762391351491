import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "GraphQL",
  "sort": 2,
  "title": "Endpoints"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Query execution`}</h2>
    <p>{`GraphQL queries can be executed against `}<inlineCode parentName="p">{`https://signatures-api.criipto.com/v1/graphql`}</inlineCode>{`. Queries must be sent as a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request containing a `}<inlineCode parentName="p">{`JSON`}</inlineCode>{` body with the parameters `}<inlineCode parentName="p">{`query`}</inlineCode>{` and optionally `}<inlineCode parentName="p">{`variables`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST -H "Content-Type: application/json" \\
  -u clientId:clientSecret \\
  --data '{"query": "... graphql query ...", "variables": {"key": "value"}}'\\ 
  https://signatures-api.criipto.com/v1/graphql
`}</code></pre>
    <h2>{`Schema`}</h2>
    <p>{`If you need to fetch the schema for build/codegen tools you can do so by executing a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request against `}<inlineCode parentName="p">{`https://signatures-api.criipto.com/v1/graphql`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`curl https://signatures-api.criipto.com/v1/graphql
`}</code></pre>
    <h2>{`Explorer`}</h2>
    <p>{`A general purpose GraphQL Explorer is available at `}<a parentName="p" {...{
        "href": "https://signatures-api.criipto.com/v1/explorer"
      }}>{`https://signatures-api.criipto.com/v1/explorer`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      